<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
<!--        <label>Show</label>
        <v-select
            @on-change="paginateChange"
            :options="perPageOptions"
            v-model="search.paginate"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>-->
      </b-col>

      <!-- Search -->
      <b-col cols="12" md="4">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
              v-model="search.search_data"
              class="d-inline-block mr-1"
              placeholder="Search..."
          />
          <b-dropdown
              text="Action"
              variant="outline-primary"
              :disabled="!select.selected"
          >
            <b-dropdown-item> Delete</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-card no-body>
      <div class="m-2">

      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th class="align-middle text-center">Sl</th>
            <th class="align-middle">Student Info</th>
            <th class="align-middle">Course Info</th>
            <th class="text-center">Type / Status</th>
            <th class="align-middle">Remark</th>
            <th class="align-middle">Replies</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(ticket, index) in tickets.data"
              :key="index"
              v-if="tickets.data.length"
          >
            <td class="align-middle text-center">
              {{ tickets.from + index }}
            </td>
            <td class="align-middle">
              <div v-if="ticket.student">
                <p class="m-0 p-0">
                  <b>Name : </b>{{ ticket.student.name }}
                </p>
                <p class="m-0 p-0" v-if="ticket.student.number">
                  <b>Number : </b>{{ ticket.student.number }}
                </p>
              </div>
            </td>
            <td class="align-middle">
              <p class="mb-0" v-if="ticket.course">
                <b>Course name : </b>{{ ticket.course.name }}
              </p>
              <p class="mb-0" v-if="ticket.teacher">
                <b>Teacher name : </b>{{ ticket.teacher.name }}
              </p>
            </td>
            <td class="align-middle">
              <div class="d-flex">
                <div>
                  <p class="mb-0">
                  <span
                      class="badge"
                      style="margin: 5px"
                      :class="
                      ticket.type == 1
                        ? 'badge-dark'
                        : 'badge-primary'
                    "
                  >
                    {{
                      ticket.type == 1
                          ? 'Academic Support'
                          : 'Technical Support'
                    }}
                  </span>
                  </p>
                </div>
                <div>
                  <p class="mb-0">
                  <span
                      class="badge"
                      style="margin: 5px"
                      :class="
                      ticket.status == 3
                        ? 'badge-warning'
                        : (ticket.status == 1 ? 'badge-success' : 'badge-primary')
                    "
                  >
                    {{
                      ticket.status == 3
                          ? 'Pending'
                          : (ticket.status == 1 ? 'Closed' : 'In Progress')
                    }}
                  </span>
                  </p>
                </div>
              </div>
            </td>
            <td style="width: 400px" class="align-middle">
              <p class="mb-0" v-if="ticket.remark">{{ ticket.remark }}</p>
              <p class="mb-0" v-if="ticket.created_at">
                <b>Created At : </b
                >{{ ticket.created_at | date_time_format }}
              </p>
            </td>
            <td class="align-middle">
              <button
                  v-if=""
                  class="btn btn-primary btn-sm"
                  @click="getRepliesModel(ticket.id, ticket)"
              >
                <feather-icon size="18" icon="MessageSquareIcon"/>
              </button>
            </td>

            <Modal
                v-model="reply_modal"
                :closable="true"
                :mask-closable="false"
                width="700px"
                class-name="supportTicket"
            >
              <div slot="header">
                <div class="d-flex justify-content-between">
                  <div>
                    <h4 style="padding-top: 8px">Support Ticket Manage</h4>
                  </div>
                  <div class="mr-3">
                    <Select
                        v-model="form.status"
                        @on-change="changeStatus()"
                        name="status"
                        id="status"
                        placeholder="Select status"
                    >
                      <Option :value="3" >Pending</Option>
                      <Option :value="2">In Progress</Option>
                      <Option :value="1">Done</Option>
                    </Select>
                  </div>
                </div>
              </div>

              <div class="pt-1">
                <h3 v-if="ticket.remark">{{ticket.remark}}</h3>
                <hr>
              </div>
              <Scroll >
                <section>
                  <div>
                    <div v-if="replies.length">
                      <ul>
                        <li class="p-1 rounded mb-1"
                            v-for="(reply, index) in replies"
                            :key="index"
                            v-if="replies.length"
                            :class="reply.followup_by || reply.teacher ? 'bg-primary text-white' : 'bg-light'"
                            style="list-style: none">
                          {{ reply.message }}
                          <div class="text-right mt-1">
                            <div class="d-flex justify-content-between">
                              <div>
                                <small v-if="!reply.teacher && reply.followup_by">
                                  <b-button variant="none" type="submit" class="p-0 mr-1" @click="editReply(reply.id)">
                                    <feather-icon icon="EditIcon" :class="reply.followup_by || reply.teacher ? 'text-white' : 'text-black-50'"></feather-icon>
                                  </b-button>
                                  <b-button variant="none" type="submit" class="p-0" @click="deleteReply(reply.id)">
                                    <feather-icon icon="Trash2Icon" :class="reply.followup_by || reply.teacher ? 'text-white' : 'text-black-50'"></feather-icon>
                                  </b-button>
                                </small>
                              </div>
                              <div>
                                <small>
                                  <p class="mb-0" v-if="reply.created_at">{{ reply.created_at | date_time_format }}</p>
                                  <p class="mb-0 font-weight-bolder">
                                    {{
                                      reply.followup_by ? reply.followup_by.name : (reply.teacher ? reply.teacher.name : 'Student')
                                    }}</p>
                                </small>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-else class="text-center">
                      <h3 class="badge badge-danger">No Replies!</h3>
                    </div>
                  </div>
                </section>
              </Scroll>
              <div slot="footer">
                <b-form
                    class="auth-login-form mt-1"
                    @submit.prevent
                    enctype="multipart/form-data"
                >
                  <div style="display: inline">
                    <div class="col-md-12">
                      <b-form-group label-for="reply">
                        <b-form-textarea
                            id="reply"
                            v-model="form.message"
                            name="reply"
                            :value="form.message"
                            placeholder="Enter your reply"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-12 pb-1">
                      <!-- submit buttons -->
                      <b-button type="submit" variant="primary" @click="sentReply()">
                        <feather-icon :icon="form.id ? 'RepeatIcon' : 'SendIcon'"></feather-icon>
                      </b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </Modal>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
            >Showing {{ tickets.from ? tickets.from : 0 }} to
              {{ tickets.to ? tickets.to : 0 }} of
              {{ tickets.total ? tickets.total : 0 }}
              entries</span
            >
          </b-col>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
                :data="tickets"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider, BAvatar,
} from "bootstrap-vue";
import {required} from "@validations";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  data() {
    return {
      reply_modal: false,
      form: {
        id: "",
        support_ticket_id: "",
        teacher_id: "",
        followup_by: "",
        message: "",
        status: ""
      },
      updateStatus:null,
      search: {
        page: 1
      },
      list1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      perPageOptions: [10, 25, 50, 100],
      tickets: {},
      replies: {},
      image: this.$store.state.base_url + "images/student/default.svg",

      select: {
        data: [],
        selected: false,
      },
      selectedAll: false,
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const tickets = await this.callApi(
          "get",
          "/app/student/get/support/tickets?page=" + this.search.page,
      );
      if (tickets.status == 200) {
        this.tickets = tickets.data.tickets;
      }
    },

    clear(){
      this.form.id = null;
      this.form.message = null;
    },

    async getRepliesModel(ticketId, ticket) {
      this.form.status = ticket.status;
      this.form.support_ticket_id = ticketId;
      this.getReplies();
      this.reply_modal = true;
    },

    async getReplies() {
      const replies = await this.callApi(
          "get",
          "/app/student/support/ticket/reply/" + this.form.support_ticket_id
      );
      if(replies.status == 200){
        this.replies = replies.data.replies
      }
    },

    sentReply() {
      if(this.form.message == null || this.form.message == ""){
       return this.e('Message field is required.')
      }

      if(this.form.id){
        axios
            .put("/app/student/support/ticket/reply/" + this.form.id, this.form)
            .then((res) => {
              this.s(res.data.message);
              this.getReplies();
              this.clear();
            })
            .catch((e) => {
              if (e.response.status === 422) {
                if (e.response.data.errors.message)
                  this.e(e.response.data.errors.message[0]);
              }
            });
      }
      else {
        axios
            .post("/app/student/support/ticket/reply", this.form)
            .then((res) => {
              this.s(res.data.message);
              this.getReplies();
              this.clear();
            })
            .catch((e) => {
              if (e.response.status === 422) {
                if (e.response.data.errors.message)
                  this.e(e.response.data.errors.message[0]);
              }
            });
      }
    },

    changeStatus() {
        axios
            .post("/app/student/update/support/ticket/status/" + this.form.support_ticket_id, {status: this.form.status})
            .then((res) => {
              this.s(res.data.message);
              this.getReplies(this.form.support_ticket_id);
              this.getResults();
            })
            .catch((e) => {
              if (e.response.status === 422) {
                if (e.response.data.errors.message)
                  this.e(e.response.data.errors.message[0]);
              }
            });
    },

    editReply(replyId) {
      axios
          .get("/app/student/support/ticket/reply/" + replyId + "/edit")
          .then((res) => {
            this.getReplies(this.form.support_ticket_id);
            this.form.id = res.data.ticketReply.id;
            this.form.message = res.data.ticketReply.message;
            this.form.support_ticket_id = res.data.ticketReply.support_ticket_id;
            this.form.followup_by = res.data.ticketReply.followup_by;
            this.form.teacher_id = res.data.ticketReply.teacher_id;
          })
          .catch((e) => {
            if (e.response.status === 422) {
              if (e.response.data.errors.message)
                this.e(e.response.data.errors.message[0]);
            }
          });
    },

    deleteReply(replyId) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this message!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
              .delete("/app/student/support/ticket/reply/" + replyId)
              .then((res) => {
                this.s(res.data.message);
                this.getReplies(this.form.support_ticket_id);
              })
              .catch((e) => {
                this.e(e.response.data.message);
              });
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  components: {
    BAvatar,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
};

</script>

<style scoped>
</style>
